<script>
import {mapActions, mapGetters} from "vuex";
import InputEmail from "@/components/Inputs/base/InputEmail";
import InputCode from "@/components/Inputs/base/InputCode";

export default {
  name: "AdminAuth",
  components: {InputCode, InputEmail},
  data: function() {
    return {
      isEmailValid: false,
      isLoadingCodeAndCaptcha: false,
      codeInput: false,
    };
  },
  computed: {
    ...mapGetters("User", { getCredentialsCode: "getCredentialsCode"})
  },
  methods: {
    ...mapActions("User", {
      code: "code",
      login: "login",
      init: "init",
    }),
    async signin() {
      await this.login()
      await this.init()
      await this.$router.push("/admin")
    },
    async recaptcha() {
      this.isLoadingCodeAndCaptcha = true
      await this.$recaptchaLoaded()
      this.$store.commit("User/CAPTCHA", await this.$recaptcha('login'))
      await this.code()
      this.isLoadingCodeAndCaptcha = false
      this.codeInput = true
    }
  }
}
</script>

<template>
  <v-container class="fill-height">
    <v-row justify="center">
      <v-col
        cols="12"
        sm="10"
        md="8"
        lg="6"
        xl="4"
      >
        <p>Авторизация для администратора</p>
        <v-form
          v-model="isEmailValid"
          @submit.prevent
        >
          <InputEmail
            :disabled="codeInput"
            @enter="recaptcha"
          />
        </v-form>
        <v-btn
          v-show="!codeInput"
          :disabled="!isEmailValid"
          :loading="isLoadingCodeAndCaptcha"
          large
          block
          tile
          depressed
          @click="recaptcha"
        >
          Получить код
        </v-btn>
        <InputCode v-show="codeInput" />
        <v-btn
          v-show="codeInput"
          depressed
          tile
          block
          large
          :disabled="!getCredentialsCode"
          color="primary"
          @click="signin"
        >
          Войти
        </v-btn>
      </v-col>
    </v-row>
  </v-container>
</template>